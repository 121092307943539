<template>
  <div class="reward-wrapper">
    <div v-for="(item, index) in tokenInfoList" :key="index">
      <div class="list-item" >
        <div class="title">{{item.symbol}}</div>
        <div class="price-line">
          <div class="price">{{$gbUtils.formatSixBalance(item.pending + item.withdrawables)}} {{item.symbol}}</div>
          <van-button class="btn" :loading="harvestLoading && index === harvestIndex" :disabled="item.pending + item.withdrawables > 0 ? false : true" @click="handleToHarvest(item.tokenAddress, index)">{{ecologicalReward.claim}}</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reward from '@/utils/reward.js'
import web3 from '@/utils/web3.js'
export default {
  data () {
    return {
      tokenInfoList: [], // 代币信息列表
      pendingList: [],
      withdrawablesList: [],
      harvestLoading: false,
      canHarvest: false,
      harvestIndex: 0
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ecologicalReward () {
      return this.$t('ecologicalReward')
    }
  },
  methods: {
    init () {
      this.$emit('changeLoading', true)
      this.getRewardTokenList()
    },
    // 获取所有可奖励代币合约的地址
    async getRewardTokenList () {
      const resp = await reward.rewardTokenList()
      if (!resp.success) return
      this.tokenInfoList = resp.result.map((address, index) => {
        this.getSymbol(address, index)
        this.getWithdrawables(address, index)
        return {
          tokenAddress: address,
          symbol: '',
          withdrawables: 0,
          pending: 0
        }
      })
      this.getPending()
    },
    // 获取代币名称
    async getSymbol (tokenAddress, index) {
      const resp = await web3.getSymbol(tokenAddress)
      if (!resp.success) return
      this.tokenInfoList[index].symbol = resp.result
    },
    // 获取代币奖励
    async getPending () {
      const resp = await reward.pending()
      console.log('getPending', resp)
      if (!resp.success) return
      resp.result.pendings.forEach((element, index) => {
        this.tokenInfoList[index].pending = parseFloat(web3.fromWei(element.toString()))
      })
    },
    // 获取代币奖励
    async getWithdrawables (tokenAddress, index) {
      const resp = await reward.withdrawables(tokenAddress)
      console.log('getWithdrawables', resp.result)
      if (!resp.success) return
      this.tokenInfoList[index].withdrawables = parseFloat(web3.fromWei(resp.result.toString()))
      this.$emit('changeLoading', false)
    },
    // 领取
    async handleToHarvest (tokenAddress, index) {
      console.log(tokenAddress)
      this.harvestLoading = true
      this.harvestIndex = index
      const resp = await reward.harvest(tokenAddress)
      this.harvestLoading = false
      if (!resp.success) return
      this.init()
      this.$toast('success')
      console.log('harvest', resp.result)
    }
  }
}
</script>

<style lang="scss" scoped>
.reward-wrapper {
  width: 100%;
  background: #F8F8F8;
  box-sizing: border-box;
  padding: 12px;
  .list-item {
    height: 96px;
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 12px;
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.3);
      line-height: 18px;
    }
    .price-line {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .price {
        font-size: 20px;
        font-weight: 500;
        color: #16172A;
        line-height: 30px;
      }
      .btn {
        width: 68px;
        height: 30px;
        border-radius: 8px;
        border: 1px solid #25E2B1;
        font-size: 14px;
        font-weight: 500;
        color: #25E2B1;
        line-height: 30px;
        text-align: center;
      }
    }
  }
}
</style>
